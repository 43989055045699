.aboutMeWrapper {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: space-around;
  background-color: #f0f0f0;
}

.aboutMeConteiner {
  display: flex;
}

@media (max-width: 450px) {
  .aboutMeConteiner {
    flex-direction: column;
  }
}

.aboutMeCopy {
  height: 500px;
  width: 300px;
  margin: 4rem;
}

.aboutMeCopy h3 {
  font: bold 6rem "Source Sans 3";
  color: #171717;
  text-decoration: none;
  background: linear-gradient(to top, #fdf100, #fdf100) bottom/0 0.3em no-repeat;
  transition: 1s background-size;
  background-size: 100% 0.3em;
}

.aboutMeCopy p {
  text-indent: 20px;
  text-align: justify;
}

.fotoProfile {
  background-image: url("../../assets/profile/foto_KP.jpeg");
  background-size: cover;
  background-position: center;
  width: 300px;
  margin: 4rem;
}
