.contactFormWrapper {
  background-color: #171717;
  margin: 4rem 0;
  padding: 3rem 8rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #f0f0f0;
}

@media (max-width: 450px) {
  .contactFormWrapper {
    flex-direction: column;
    flex-wrap: wrap;
    text-align: center;
    margin: 4rem 0;
    padding: 0;
  }
}

.imgStyled {
  display: flex;
  align-items: center;
  color: #f0f0f0;
}

.imgStyled img {
  width: 60px;
  border-radius: 40px;
  margin: 0 10px 0 0;
  border-color: #f0f0f0;
  border-style: groove;
}

@media (max-width: 450px) {
  .imgStyled img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px;
  }
}

.styledSVG {
  width: 60px;
  margin: 0 1rem;
}

.styledSVG:hover {
  fill: #fdf100;
  transform: scale(1.1);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  cursor: pointer;
}

.styledSVGContact {
  width: 20px;
  margin: 0 1rem 0 0;
}

.midStyled {
  position: relative;
  height: 200px;
}

.rightCopy {
  text-align: left;
}

@media (max-width: 450px) {
  .rightCopy {
    text-align: center;
  }
}

.divContact {
  display: flex;
  align-items: center;
  color: #f0f0f0;
}

@media (max-width: 450px) {
  .divContact {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f0f0f0;
    margin: 10px;
  }
}
