* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
}

i {
  cursor: pointer;
}

h1 {
  font-family: "Source Sans 3", sans-serif;
}

h2 {
  font-family: "Source Sans 3", sans-serif;
}

h3 {
  font-family: "Source Sans 3", sans-serif;
}

h4 {
  font-family: "Source Sans 3", sans-serif;
}

h5 {
  font-family: "Source Sans 3", sans-serif;
}

link {
  font-family: "Source Sans 3", sans-serif;
}

p {
  font-family: "Source Sans 3", sans-serif;
}

a {
  font-family: "Source Sans 3", sans-serif;
}
