.navWrapper {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.navCopy {
  font: bold 15px "Source Sans 3";
  display: flex;
  align-items: center;
  background: #171717;
  color: #f0f0f0;
  overflow: hidden;
  width: 25%;
  justify-content: center;
  height: 2rem;
}

.nav {
  display: flex;
  align-items: center;
  background: #5d5d5d;
  height: 2rem;
  overflow: hidden;
  width: 75%;
  justify-content: center;
}

@media (max-width: 450px) {
  .nav {
    position: absolute;
    width: 100%;
  }
}

.styledNav {
  font: bold 10px "Source Sans 3";
  text-decoration: none;
  color: #f0f0f0;
  padding: 5px 0;
  margin: 0 30px;
  text-decoration: none;
  background: linear-gradient(to top, #fdf100, #fdf100) bottom/0 0.3em no-repeat;
  transition: 1s background-size;
}

.styledNav:hover {
  text-decoration: none;
  background-size: 100% 0.3em;
  cursor: pointer;
}
