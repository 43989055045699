.footerDiv {
  background-color: #f0f0f0;
  color: #171717;
  padding: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .footerDiv .footer {
    font-size: 0.8rem;
  }
}
