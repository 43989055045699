.portfolioCopy {
  margin: 4rem;
  text-align: center;
}

.portfolioCopy h3 {
  font: bold 6rem "Source Sans 3";
  color: #171717;
  text-decoration: none;
  background: linear-gradient(to top, #fdf000, #fdf000) bottom/0 0.3em no-repeat;
  transition: 1s background-size;
  background-size: 20% 0.3em;
}

@media (max-width: 450px) {
  .portfolioCopy h3 {
    font: bold 2rem "Source Sans 3";
  }
}

.portfolioCopy p {
  text-indent: 20px;
  text-align: justify;
}

.tiles {
  padding: 6vh 16vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

@media (max-width: 450px) {
  .tiles {
    padding: 0vh 4vw;
  }
}

.imageStyle {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.modalStyle {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  box-sizing: border-box;
}

.modalImageStyle {
  max-width: 50%;
}

.tile {
  margin: 15px;
  cursor: pointer;
  overflow: hidden;
  width: 18vw;
  height: 18vw;
}

@media (max-width: 450px) {
  .tile {
    width: auto;
    height: auto;
  }
}

.tile img {
  width: 100%;
  transition: transform 500ms ease;
}

.tile img:hover {
  transform: scale(1.01);
}

.tile video {
  width: 18vw;
  height: 18vw;
  background-size: cover;
  background-position: center;
  position: absolute;
}

@media (max-width: 450px) {
  .tile video {
    width: 100%;
    height: auto;
    transition: transform 500ms ease;
    position: static;
  }
}

.styledButton {
  text-align: center;
}

.styledButton button {
  font: bold 2rem "Source Sans 3";
  text-decoration: none;
  border: none;
  color: #171717;
  padding: 5px 0;
  margin: 0 30px;
  text-decoration: none;
  background: linear-gradient(to top, #fdf000, #fdf000) bottom/0 0.3em no-repeat;
  transition: 1s background-size;
}

@media (max-width: 450px) {
  .styledButton button {
    font: bold 1rem "Source Sans 3";
  }
}

.styledButton button:hover {
  text-decoration: none;
  background-size: 100% 0.3em;
  cursor: pointer;
}

@media screen and (min-width: 425) {
  .tile img {
    width: 20vw;
    height: auto;
  }
}

@media screen and (min-width: 768px) {
  .tiles {
    padding: 1vh 2vw;
  }
  .tile {
    width: 18vw;
    height: 18vw;
  }
}

@media screen and (min-width: 1024px) {
  .tiles {
    padding: 6vh 16vw;
  }
  .tile {
    margin: 15px;
    width: 18vw;
    height: 18vw;
  }
}
